import { theme } from '@helloworld1812/ws-components';
import type { WsComponentsTheme } from '@helloworld1812/ws-components';

export default {
  ...theme,
};

declare module 'styled-components' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface DefaultTheme extends WsComponentsTheme {}
}
