import { ModuleType } from '@/routes/employee-onboarding/process-setup/interface';

export default {
  ACCOUNTS: 'account',
  SCHEDULING: 'scheduling',
  COMPANY: 'company',
  BRANDS: 'brands',
  NOTIFICATION: 'notification',
  PERMISSIONS: 'permissions',
  CUSTOM_FIELDS: 'customFields',
  DATA_EXPORTS: 'dataExports',
  INTEGRATIONS: 'integrations',
  MANAGE_USERS: 'manageUsers',
  SOURCING_TOOLS: 'sourcingTools',
  RATING_PLANS: 'ratingPlans',
  REAPPLICATIONS: 'reapplications',
  HR_REFERRAL: 'referral',
  MINIMAL_BULK_EDIT_COUNT: 1,
  MINIMAL_BULK_DUPLICATE_COUNT: 1,
  POSITION_BULK_LIMIT: 100,
  HR_REFERRAL_REWARD: '$200',
  ACCESSTOKEN: 'accesstoken',
  E_VERIFY_QUIZ: 'eVerifyQuiz',
  POSITION: 'position',
  HIRING_PROCESS_TEMPLATE: 'hiring_process_template',
  LOCATION: 'location',
  DEPARTMENT: 'department',
  ROLE_MANAGEMENT: 'role_management',
  TAX_ENTITY: 'tax_entity',
  AI_ASSISTANT: 'ai_assistant',

  STRINGS: {
    SEARCH_TYPE_LOCATION: 'location',
    SEARCH_TYPE_DEPARTMENT: 'department',
    ADDRESS_FILL_TYPE_GOOGLE: 'google',
    ADDRESS_FILL_TYPE_MANUALLY: 'manually',
    ADDRESS_FILL_TYPE_REMOTE: 'remote',
  },

  ROLES: {
    SUPER_ADMIN: 'super_admin',
    GENERAL_MANAGER: 'general_manager',
  },

  RESERVED_ROLES: ['Super Admin', 'General Manager'],

  ROUTES: {
    ALL_POSITIONS: 'all',
    ALL_APPLICANTS: 'all-applicants',
  },

  ERROR_CODE: {
    NO_ACCESS_ERROR_CODE: 10004,
    PAGE_NOT_FOUND_ERROR_CODE: 40001,
  },

  EDIT_POSITION: {
    CREATE_NEW_LOCATION: 'create new position',
    CREATE_NEW_DEPARTMENT: 'create new department',
  },

  AUTOMATIONS: {
    APPLICATION_ADVANCE: 'application advance',
    QUIZ_ADVANCE: 'quiz advance',
    FORM_ADVANCE: 'form advance',
    OFFER_SEND: 'send offer',
    OFFER_ADVANCE: 'offer advance',
    QUIZ_REMINDER: 'quiz reminder',
    FORM_REMINDER: 'form reminder',
    OFFER_REMINDER: 'offer reminder',
    MEETING_REMINDER: 'meeting reminder',
    SCHEDULING_REMINDER: 'scheduling reminder',
    BACKGROUND_CHECK_SEND: 'send background check request',
    BACKGROUND_CHECK_REMINDER: 'background_check reminder',
    BACKGROUND_CHECK_ADVANCE: 'background check advance',
    AUTO_REJECTION: 'auto rejection',
  },

  // every stage type defined in backend
  STAGE_TYPE: {
    APPLICATION_FORM: 'online_form',
    ONLINE_FORM: 'form',
    ONLINE_QUIZ: 'online_quiz',
    OFFER: 'offer',
    PHONE_INTERVIEW: 'phone_interview',
    ONSITE_INTERVIEW: 'onsite_interview',
    SCHEDULING: 'scheduling_stage',
    BACKGROUND_CHECK: 'background_check',
    REVIEW: 'review',
    LONG_TERM_HOLD: 'long_term_hold',
    HIRING_COMPLETE: 'hiring_complete',
    CUSTOM: 'custom',
  },

  STAGE_SETTINGS: {
    MENU_KEY_APPLICATION_FORM_QUESTIONS: 'application form questions menu',
    MENU_KEY_FORM_QUESTIONS: 'form questions menu',
    MENU_KEY_QUIZ_QUESTIONS: 'quiz questions menu',
    MENU_KEY_LETTER: 'letter menu',
    MENU_KEY_AUTOMATION_RULES: 'automation rule menu',
    MENU_KEY_OFFER: 'offer menu',
    MENU_KEY_AVAILABILITY: 'availability menu',
    MENU_KEY_BACKGROUND_CHECK_SETTINGS: 'background check settings menu',
    MENU_KEY_HOLDING_STAGE: 'holding stage menu',
    MENU_KEY_AUTOMATIONS: 'automations',
  },

  SCHEDULING_APPLICANT_STATE: {
    NOT_SUBMITTED: 'Not Submitted',
    CONFIRMED: 'Confirmed',
    PENDING: 'Pending',
    REQUEST_RESCHEDULE: 'Request Reschedule',
    CANCELLED: 'Cancelled',
    COMPLETED: 'Completed',
    NO_SHOW: 'No Show',
  },

  EMPLOYEE_ONBOARDING_STATE: {
    not_started: 'not_started',
    need_attention: 'need_attention',
    in_progress: 'in_progress',
    completed: 'completed',
    suspended: 'suspended',
    action_required: 'action_required',
  },

  EMPLOYEE_ONBOARDING_PROCESS_STATE: {
    not_started: 'not_started',
    in_progress: 'in_progress',
    completed: 'completed',
    suspended: 'suspended',
  },

  ACTIONS: {
    REJECT_APPLICANT: 'reject applicant',
    UNREJECT_APPLICANT: 'unreject applicant',
    MOVE_TO_STAGE: 'move to stage',
    RESCHEDULE: 'reschedule',
    FEEDBACK: 'feedback',
    TOGGLE_HIRED: 'toggle hired',
    VIEWED_MESSAGE: 'viewed message',
  },

  FUNCTION_MODULES: {
    JOB_POSTING: 'job_posting',
    DATA_ANALYTICS: 'data_analytics',
    SCHEDULING: 'scheduling',
    BULK_EDIT_POSITIONS: 'bulk_update_positions',
    SHORT_CODE: 'twilio_short_code',
    EMPLOYEE_ONBOARDING: 'onboarding',
    HIRING_STAGES: 'hiring_stages',
    BULK_DUPLICATE_POSITIONS: 'bulk_duplicate_positions',
    BULK_PUBLISH_POSITIONS: 'bulk_publish_positions',
    PIXEL_TRACKING: 'conversion_tracking',
    PAYLOCITY_INTEGRATION_WS_HIRING_TO_PAYLOCITY_ONBOARDING: 'paylocity_integration_ws_hiring_to_paylocity_onboarding',
    UKG_INTEGRATION_WS_HIRING_TO_UKG_ONBOARDING: 'ukg_integration_ws_hiring_to_ukg_onboarding',
    PAYLOCITY_INTEGRATION_COMPANY_SETTING_PAGE: 'paylocity_integration_company_setting_page',
    CFA_VENDORBRIDGE: 'cfa_vendorbridge',
    PAYCHEX: 'paychex',
    USCIS_INTEGRATION: 'uscis_integration',
    SPANISH_TRANSLATION: 'spanish_translation',
    TEAM_RECORDS: 'team_records',
    ADP_RUN_INTEGRATION: 'adp_run_integration',
    AI_ASSISTANT: 'ai_assistant',
    ADHOC_DOCUMENTS: 'adhoc_documents',
    PAYROLL: 'payroll',
    MESSAGING: 'messaging',
    APPLICATION_TRACKING: 'application_tracking',
  } as const,

  USER_PERMISSIONS: {
    EDIT_POSITION: 'edit_position',
    ALL_ADMIN_RIGHTS: 'all_admin_rights',
    EDIT_ALL_CALENDARS: 'edit_all_calendars',
    EDIT_EVALUATION: 'edit_evaluation',
    EDIT_COMPANY: 'edit_company',
    EDIT_DEPARTMENT: 'edit_department',
    EDIT_INTEGRATION: 'edit_integration',
    EDIT_LOCATION: 'edit_location',
    EDIT_MESSAGE: 'edit_message',
    EDIT_FEEDBACK: 'edit_feedback',
    EDIT_NOTE: 'edit_note',
    EDIT_PERMISSION: 'edit_permission',
    EDIT_SPONSORED_JOB: 'edit_sponsored_job',
    MANAGE_ONBOARDING: 'manage_onboarding',
    EDIT_USER: 'edit_user',
    EDIT_RECORD: 'edit_record',
    MANAGE_TEAM_RECORD: 'manage_team_record',
    IMPORT_AND_DELETE_RECORD: 'import_and_delete_record',
    EDIT_COMPANY_DOCUMENT: 'edit_company_document',
    ASSIGN_COMPANY_DOCUMENT: 'assign_company_document',

    CREATE_POSITION: 'create_position',
    UPDATE_POSITION: 'update_position',
    DELETE_POSITION: 'delete_position',
    EDIT_POSITION_TEMPLATE: 'edit_position_template',
    EDIT_HIRING_PROCESS: 'edit_hiring_process',
    EDIT_HIRING_PROCESS_TEMPLATE: 'edit_hiring_process_template',
    EDIT_JOB_DETAIL: 'edit_job_detail',
    EDIT_JOB_TITLE: 'edit_job_title',
    EDIT_RATE_OF_PAY: 'edit_rate_of_pay',
    EDIT_JOB_TYPE: 'edit_job_and_remote_type',
    EDIT_LOCATION_AND_DEPARTMENT: 'edit_location_and_department', // this if for a position
    EDIT_JOB_DESCRIPTION: 'edit_job_description',
    PUBLISH_POSITION: 'publish_position',
    ONBOARD_EMPLOYEE: 'onboard_employee',
    EDIT_ONBOARDING_PROCESS: 'edit_onboarding_process',
    MANUALLY_COMPLETE_MODULE: 'manually_complete_module',
    CUSTOMIZE_NOTIFICATIONS: 'customize_notifications',
    EDIT_CUSTOM_REPORTS: 'edit_custom_reports',

    VIEW_SENSITIVE_PII: 'view_sensitive_pii',

    MANAGE_PAYROLL_TEAM_MEMBERS: 'manage_payroll_team_members',
  } as const,

  CHECKR_FEE_NOTE: 'Pricing per applicant. Additional screening and data access fees may apply.',

  INTEGRATIONS_NICKNAME: {
    CHECKR: 'checkr',
  },

  INTEGRATION_APP_NAME: {
    PAYLOCITY: ModuleType.PAYLOCITY,
    CFA_VENDORBRIDGE: ModuleType.CFA,
    ADP_RUN: ModuleType.ADP_RUN,
    ADP_RUN_SELF_ONBOARDING: ModuleType.ADP_RUN_SELF_ONBOARDING,
  },

  SCHEDULING_FILTERS: {
    EVENT_TYPES: {
      ALL_EVENTS: 'All time slots',
      UNCONFIRMED_EVENTS: 'Time slots without confirmed applicants',
      CONFIRMED_EVENTS: 'Time slots with confirmed applicants',
    },
    CALENDARS: {
      ALL_EVENTS: 'All calendars',
      MY_EVENTS: 'My calendar',
      GOOGLE_EVENTS: 'My Google Calendar',
      OUTLOOK_EVENTS: 'My Outlook Calendar',
      TEAM_EVENTS: 'Team calendars',
    },
  },

  // job board
  JOB_BOARD: {
    INDEED_SPONSOR_NAME: 'Indeed Sponsored',
    INDEED_FREE_NAME: 'Indeed',
    KSL_SPONSOR_NAME: 'KSL',
    CRAIGSLIST_SPONSOR_NAME: 'Craigslist Jobs',
    CRAIGSLIST_GIGS_NAME: 'Craigslist Gigs',
    ZIPRECRUITER_NAME: 'ZipRecruiter',
    ZIPRECRUITER_SPONSOR_NAME: 'ZipRecruiter Sponsored',
    LINKEDIN_NAME: 'LinkedIn',
    MONSTER_NAME: 'Monster',
    MONSTER_SPONSOR_NAME: 'Monster Sponsored',
    GOOGLE_NAME: 'Google',
    GLASSDOOR_NAME: 'Glassdoor',
    JOVEO_NAME: 'Joveo',
    JOBBLE_NAME: 'Jobble',
    NEXXT_SPONSOR_NAME: 'Nexxt.com',
    RETAIL_GIGS_SPONSOR_NAME: 'RetailGigs.com',
    HOSPITALITY_JOBSITE_SPONSOR_NAME: 'HospitalityJobsite.com',
    TALROO_SPONSOR_NAME: 'Talroo',
    JOB_TARGET_NAME: 'JobTarget',
  },

  // job board type
  JOB_BOARD_TYPE: {
    DIRECT: 'direct',
    JOB_TARGET: 'job_target',
  },

  // Bulk Edit timeslots
  BULK_EVENT_UPDATE_TYPE: {
    PENDING_UPDATE: 0,
    PENDING_DELETE: 1,
    UPDATE_ONLY_THIS: 2,
    UPDATE_ALL: 3,
    DELETE_ONLY_THIS: 4,
    DELETE_ALL: 5,
  },

  // Postion bulk editing
  JOB_DETAILS_ACTION_NAMES: {
    EDIT_FIXED_SECTION: 'edit fixed section',
    CANCEL_EDIT_FIXED_SECTION: 'cancel edit fixed section',
    EDIT_DYNAMIC_SECTION: 'edit dynamic section',
    DELETE_DYNAMIC_SECTION: 'delete dynamic section',
    REORDER_DYNAMIC_SECTION: 'reorder dynamic section',
    COPY_FROM_POSITION: 'copy from position',
  },

  JOB_BOARD_ACTION_NAMES: {
    EDIT_JOB_BOARD: 'edit job board',
    CANCEL_EDIT_JOB_BOARD: 'cancel edit job board',
  },

  STAGES_ACTION_NAMES: {
    REPLACE_ALL_STAGES: 'replace all stages',
    REPLACE_STAGE: 'replace stage',
    ADD_STAGE: 'add stage',
    DELETE_STAGE: 'delete stage',
    REORDER_STAGE: 'reorder stage',
  },

  FORM_STAGE_ACTION_NAMES: {
    COPY_QUESTIONS: 'copy form questions',
    EDIT_QUESTION: 'edit form question',
    DELETE_QUESTION: 'delete form question',
    REORDER_QUESTIONS: 'reorder form questions',
    START_EDIT_FORM_QUESTIONS: 'start edit form questions',
    CANCEL_EDIT_FORM_QUESTIONS: 'cancel edit form questions',
  },

  REJECTION_LETTER_ACTION_NAMES: {
    COPY_LETTERS_SETTINGS: 'select rejection letter templates',
    CREATE_LETTER: 'create rejection letter',
    EDIT_LETTER: 'edit rejection letter',
    DELETE_LETTER: 'delete rejection letter',
    CREATE_AUTOMATION: 'create rejection letter automation',
    EDIT_AUTOMATION: 'edit rejection letter automation',
    DELETE_AUTOMATION: 'delete rejection letter automation',
  },

  // signing document types
  SIGNING_DOCUMENT_TYPES: {
    TEXT: 'text',
    WORD: 'word',
    HELLO_SIGN: 'hello_sign',
    STANDARD: 'standard',
  },

  PAGE_TYPES: {
    ALL_APPLICANTS: 'all applicants page',
    POSITION_APPLICANTS: 'position applicants page',
    PROFILE: 'profile page',
    MESSAGES: 'messages page',
    INACTIVE: 'inactive page',
  },

  // event range types
  // In schedule settings, user can set a 'minimal scheduling notice' to prevent last minute.
  // And set a 'available date range' to prevent over booked for feature time slot,
  // since a time slot can be recurring
  EVENT_RANGE_TYPES: {
    LT_MIN: 1,
    GT_MAX: 2,
    IN_BETWEEN_MIN_MAX: 3,
  },

  // help articles
  HELP_ARTICLES: {
    add_a_position: 'http://help.workstream.is/en/articles/3183983-how-to-add-a-new-position',
    setup_availabilities:
      'http://help.workstream.is/en/articles/3185223-how-to-set-up-your-availabilities-using-workstream-calendar',
    manage_applicants:
      'http://help.workstream.is/en/articles/3416325-viewing-and-moving-your-applicants-in-your-pipeline',
    manage_user: 'http://help.workstream.is/en/articles/3193001-how-to-add-a-user',
    get_notified: 'http://help.workstream.is/en/articles/3167257-how-to-get-notified',
    scheduling_settings:
      'http://help.workstream.is/en/articles/2486232-how-to-connect-workstream-with-my-google-calendar',
    publish_a_position: 'http://help.workstream.is/en/articles/3185616-how-to-publish-a-job-description',
    edit_a_position: 'http://help.workstream.is/en/articles/2827108-customizing-your-hiring-process',
    how_to_use_messages_tab: 'http://help.workstream.is/en/articles/3388979-how-to-use-the-messages-tab',
    how_to_use_integrations: 'http://help.workstream.is/en/articles/3391658-how-to-use-integration',
  },

  ATTACHEMENT_CATEGORY: {
    application_attachment: 'application_attachment',
    application_background_check: 'application_background_check',
    application_offer_unsigned: 'application_offer_unsigned',
    application_offer_signed: 'application_offer_signed',
    application_offer_signed_zip: 'application_offer_signed_zip',
    form_answer_attachment: 'form_answer_attachment',
    message_attachment: 'message_attachment',
    employee_file_attachment: 'employee_file_attachment',
  },

  // onboarding module types
  ONBOARDING_MODULE_TYPES: {
    // adp
    ADP: 'adp',
    COLLECT_INFO: 'collect_info',
    SIGN_DOCUMENT: 'signing',
    BASIC_INFORMATION: 'basic_information',
    // employee_fill_adp_form: 'employee_fill_adp_form',
    // hr_fill_adp_form: 'hr_fill_adp_form',
    // review_and_send_to_adp: 'review_and_send_to_adp',
    WOTC: 'wotc',
    W4: 'w4',
    E_VERIFY: 'formi9_compliance_everify',
    I9: 'i9',
    DEPOSIT: 'direct_deposit',
    US_STATE_TAX: 'us_state_tax',
    PAYCHEX: 'paychex',
    COMPANY_DOCUMENTS: 'company_documents',
    MICROSERVICE: 'microservice',
    EHX_WOTC: 'ehx_wotc',
  },

  // onboarding module types
  // Hazel mark. Will remove after V2
  ONBOARDING_ACTION_TYPES: {
    EMPLOYEE_FILL_BASIC_FORM: 'employee_fill_basic_information_form',
    HR_FILL_BASIC_FORM: 'hr_fill_basic_information_form',
    EMPLOYEE_FILL_PAYCHEX_FORM: 'employee_fill_paychex_form',
    HR_FILL_PAYCHEX_FORM: 'hr_fill_paychex_form',
    REVIEW_AND_SEND_TO_PAYCHEX: 'review_and_send_to_paychex',
    EMPLOYEE_SIGN_COMPANY_DOCUMENTS: 'employee_sign_company_document',
    HR_SIGN_COMPANY_DOCUMENTS: 'hr_sign_company_document',
  },

  // Modules that are potentially affected with the restart of Basic Information
  DEPENDENCY_MODULES: [ModuleType.I9, ModuleType.W4, ModuleType.US_STATE_TAX],

  SIGNUP_TYPE: {
    SELF_SERVE: 'self serve signup',
    PODDING: 'podding signup',
  },

  ASYNC_TASK_TYPE: {
    MOVE_APPLICANTS_TO_NEXT_STAGE: 'move_applicant_to_next_stage',
    BULK_PUBLISH_POSITIONS: 'publish_position',
  } as const,

  postingReviewStatus: {
    pending: 'pending',
    flagged: 'flagged',
    approved: 'approved',
    rejected: 'rejected',
    parent_under_review: 'parent_under_review',
    parent_rejected: 'parent_rejected',
    parent_flagged: 'parent_flagged',
  } as const,

  ERROR_MESSAGES: {
    zero_assginees: 'Please select at least one assignee.',
    zero_interviewers: 'Please select at least one interviewer',
  },

  AVAILABILITY_FREQUENCY: {
    WEEKLY: 'weekly',
    ONCE: 'once',
  },
  BRAND_LOCATION_SPLIT_CHARS: 'BRAND&&&LOCATION',
  Intercome_tracking_allow_list: {
    betas: ['defined_roles_v1'],
    function_modules: [],
  },

  TAX_INFORMATION: {
    ADDRESS_LINE_1: {
      MAX_LENGTH: 35,
    },
    ADDRESS_LINE_2: {
      MAX_LENGTH: 15,
    },
  },

  SA_DASHBOARD_STEPS: {
    COMPANY_STAFFS: {
      KEY: 'company_staffs',
      NAME: 'Your managers',
    },
    POSITION_CREATION: {
      KEY: 'position_creation',
      NAME: 'All job postings',
    },
    SHARE_POSITIONS: {
      KEY: 'share_positions',
      NAME: 'Share your job postings',
    },
    SET_SCHEDULING: {
      KEY: 'set_scheduling',
      NAME: 'Their availability',
    },
    ASSIGN_INTERVIEWERS: {
      KEY: 'assign_interviewers',
      NAME: 'Assign interviewers',
    },
  },
  FEEDBACK_TITLE: 'Feedback',
  MAX_TWILIO_SMS_LENGTH: 1600,

  JIMMY_JOHNS: {
    NAME: "Jimmy John's",
  },
  DUNKIN: {
    NAME: "Dunkin'",
  },
  CHICK_FIL_A: {
    NAME: 'Chick-fil-A',
  },
  KFC: {
    NAME: 'KFC',
    STORE_ID_VISIBLE_TO_COMPANIES: [3041, 7627, 11425],
  },
  APPLEBEES: {
    NAME: "Applebee's",
  },
  ALL_VALUE: 'all',

  TIME_UNITS_IN_SECONDS: {
    SECONDS_IN_A_YEAR: 31536000,
    SECONDS_IN_A_MONTH: 2592000,
    SECONDS_IN_A_WEEK: 604800,
    SECONDS_IN_A_DAY: 86400,
    SECONDS_IN_A_HOUR: 3600,
  },
  APPLICANT_CONTEXT_STRINGS: {
    INTERVIEW_NO_SHOW: 'No Show',
    INTERVIEW_CANCELLED: 'Cancelled',
    QUALIFIED: 'is qualified',
    DID_NOT_QUALIFY: 'is not qualified',
  },
  // See Jira WS-526: Use a large page size for History to reduce pagination
  // action required by the HR user
  HISTORY_API_PAGE_SIZE: 50,
};
